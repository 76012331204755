import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./Cognitive1.css";

const Cognitive3 = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;

  // const [ validated, setValidated ] = useState(false);

  const [ q1, setQ1 ] = React.useState("");
  const [ q2, setQ2 ] = React.useState("");
  const [ q3, setQ3 ] = React.useState("");
  const [ q4, setQ4 ] = React.useState("");
  const [ q5, setQ5 ] = React.useState("");
  const [ q6, setQ6 ] = React.useState("");
  const [ q7, setQ7 ] = React.useState("");
  const [ q8, setQ8 ] = React.useState("");
  const [ q9, setQ9 ] = React.useState("");
  const [ q10, setQ10 ] = React.useState("");
  const [ q11, setQ11 ] = React.useState("");
  const [ q12, setQ12 ] = React.useState("");
  const [ q13, setQ13 ] = React.useState("");
  const [ q14, setQ14 ] = React.useState("");
  const [ q15, setQ15 ] = React.useState("");
  const [ q16, setQ16 ] = React.useState("");
  const [ q17, setQ17 ] = React.useState("");
  const [ q18, setQ18 ] = React.useState("");

  const validateInput = (value) => {
    const num = parseInt(value, 10); // 10 -> base 10 decimal
    return num >= 1 && num <=5;
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    const allAnswers = [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18];
    for (let i = 0; i < allAnswers.length; i++) {
      if (!allAnswers[i]) {
        alert(`Please fill out question ${i + 1}. Don't leave it blank`);
        return;
      }
      else if (!validateInput(allAnswers[i])) {
        alert(`Please enter a valid rating between 1 and 5 for question ${i + 1}.`);
        return;
      }
    }

    if (
      !q1 || !q2 || !q3 || !q4 || !q5 ||
      !q6 || !q7 || !q8 || !q9 || !q10 ||
      !q11 || !q12 || !q13 || !q14 || !q15 ||
      !q16 || !q17 || !q18
    ) {
      alert("Please fill out all required fields before submitting.");
      return;
    }


    console.log("Cognitive form Survey form submitted")
    let request = {
      "roomid": roomId,
      "player_num": "Player " + playerNumber,
      "q1": q1,
      "q2": q2,
      "q3": q3,
      "q4": q4,
      "q5": q5,
      "q6": q6,
      "q7": q7,
      "q8": q8,
      "q9": q9,
      "q10": q10,
      "q11": q11,
      "q12": q12,
      "q13": q13,
      "q14": q14,
      "q15": q15,
      "q16": q16,
      "q17": q17,
      "q18": q18,
    };
    
    console.log(request);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    fetch(`${process.env.REACT_APP_API_URL}/cognitive3`, options)
      .then(response => response.json())
      .then(data => console.log(data));

    console.log(request);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

    }
   // setValidated(true);
    history.push(`/thanks`);
  };

  return (
    <div className="demo-container">
      <h2> Conginitive Questionnaire 3</h2>
      <p>Please <br/>
        keep the following scale in mind as you rate each of the statements below: <br/>
        1 = extremely uncharacteristic; <br/>
        2 = somewhat uncharacteristic; <br/>
        3 = uncertain; <br/>
        4 = somewhat characteristic; <br/>
        5 = extremely characteristic. <br/></p>
      <br />
      <h4>Please answer the following questions:</h4>
      <Form>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>1. I would prefer complex to simple problems. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ1(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>2. I like to have the responsibility of handling a situation that requires a lot of thinking. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ2(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>3. Thinking is not my idea of fun. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ3(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>4. I would rather do something that requires little thought than something that is sure to
challenge my thinking abilities? </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ4(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>5. I try to anticipate and avoid situations where there is a likely chance I will have to think
indepth about something." </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ5(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>6. I find satisfaction in deliberating hard and for long hours. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ6(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>7. I only think as hard as 1 have to. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ7(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>8. I prefer to think about small, daily projects to long-term ones? </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ8(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>9. I like tasks that require little thought once I've learned them? </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ9(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>10. The idea of relying on thought to make my way to the top appeals to me. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ10(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>11. I really enjoy a task that involves coming up with new solutions to problems. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ11(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>12. Learning new ways to think doesn't excite me very much? </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ12(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>13. I prefer my life to be filled with puzzles that I must solve. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ13(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>14. The notion of thinking abstractly is appealing to me. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ14(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>15. I would prefer a task that is intellectual, difficult, and important to one that is somewhat
important but does not require much thought. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ15(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>16. 1 feel relief rather than satisfaction after completing a task that required a lot of mental
effort? </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ16(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>17. It's enough for me that something gets the job done; I don't care how or why it works? </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ17(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Form.ControlInput2">
            <Form.Label>18. I usually end up deliberating about issues even when they do not affect me personally. </Form.Label>
            <Form.Control type="text" name="" onChange={e => setQ18(e.target.value)} required />
        </Form.Group>
      </Form>
      <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
    </div >
  );
};

export default Cognitive3;
