import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./index.css";
import Home from "./Home/Home";
import Room from "./Room/Room";
import Demographic from "./Demographic/Demographic";
import EndPage from "./EndPage/EndPage";
import SurveyPage from "./SurveyPage/SurveyPage";
import AutoAssign from "./AutoAssign/AutoAssign";
import Instructions from "./Instructions/Instructions";
import Reflections from "./Reflections/Reflections";
import Cognitive1 from "./Cognitive/Cognitive1";
import Cog1_Instructions from "./Cognitive_Instructions/Cog1_Instructions";
import Cognitive2 from "./Cognitive/Cognitive2";
import Cog2_Instructions from "./Cognitive_Instructions/Cog2_Instructions";
import Cognitive3 from "./Cognitive/Cognitive3";
import Cog3_Instructions from "./Cognitive_Instructions/Cog3_Instructions";
import FinalDecision from "./FinalDecision/FinalDecision";
import EndgameSurvey from "./EndgameSurvey/EndgameSurvey";
import NewGame from "./NewGame/NewGame";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={AutoAssign} />
        <Route exact path="/reflections/:roomId/:playerNumber" component={Reflections} />
        <Route exact path="/cog1instructions/:roomId/:playerNumber" component={Cog1_Instructions} />
        <Route exact path="/cog2instructions/:roomId/:playerNumber" component={Cog2_Instructions} />
        <Route exact path="/cog3instructions/:roomId/:playerNumber" component={Cog3_Instructions} />
        <Route exact path="/cognitive1/:roomId/:playerNumber" component={Cognitive1} />
        <Route exact path="/cognitive2/:roomId/:playerNumber" component={Cognitive2} />
        <Route exact path="/cognitive3/:roomId/:playerNumber" component={Cognitive3} />
        <Route exact path="/instructions/:roomId/:playerNumber" component={Instructions} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/room/:roomId/:playerNumber" component={Room} />
        <Route exact path="/thanks" component={EndPage} />
        <Route exact path="/demographic/:roomId/:playerNumber" component={Demographic} />
        <Route exact path="/surveypage/:roomId/:playerNumber" component={SurveyPage} />
        <Route exact path="/finaldecision/:roomId/:playerNumber" component={FinalDecision} />
        <Route exact path="/endgamesurvey/:roomId/:playerNumber" component={EndgameSurvey} />
        <Route exact path="/newgame/:roomId/:playerNumber" component={NewGame} />
      </Switch>
    </Router>
  );
}

export default App;
