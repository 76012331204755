import React,{useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Row, Card, Col } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import "./SurveyPage.css";
import socketIOClient from "socket.io-client";

const SurveyPage = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;
  const [playerName, setPlayerName] = useState("")
  const socketRef = useRef();

  let gameNum = parseInt(props.location.state.game);
  let round_1 = props.location.state.round;
  let cardMessage = props.location.state.card;
  let finalGame = props.location.state.finalGame;
  let gameEndQuestions = props.location.state.gameEndQuestions;
  let feedbackQuestions = props.location.state.feedbackQuestions;
  let members_1 = props.location.state.members;
  let currentPlayer = props.location.state.currentPlayer;
  let players = props.location.state.players;

  // State to track the count of submitted surveys
  const [submittedPlayers, setSubmittedPlayers] = useState(0);
  const [showSurvey, setShowSurvey] = useState(false);
  const [questions, setquestions] = useState({
    q1: { a: 0, b: 0 },
    q2: { a: 0, b: 0 },
    q3: { a: 0, b: 0 },
    q4: { a: 0, b: 0 },
    q5: { a: 1, b: 1 },
    q6: { a: 1, b: 1 }
  });
  const [iswaiting, setShowWaitPage] = useState(false);

  const SURVEY_SUBMIT_EVENT = "surveySubmit";
  const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
  
  socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
    query: { roomId, playerNumber }
  });

  useEffect(() => {
    const currentPlayer = members_1.find(member => member.socketid === socketRef.current.id);
    if (currentPlayer) {
      setPlayerName(currentPlayer.name || `Player ${currentPlayer.number}`);
    }
  }, [members_1, socketRef, players, history, roomId, playerNumber, gameNum, currentPlayer, finalGame, gameEndQuestions, cardMessage]);

  // Listen for the survey submission event from other players
  useEffect(() => {
    socketRef.current.on(SURVEY_SUBMIT_EVENT, (count) => {
      setSubmittedPlayers(count); // Update the count of submitted players
    });
  }, []);

  // Function to handle survey submission
  const sendSurveySubmit = (val) => {
    socketRef.current.emit(SURVEY_SUBMIT_EVENT, val); // Emit event when survey is submitted
    return val; 
  };

  // This will track when both players have submitted
  useEffect(() => {
    if (submittedPlayers === players) {
      // Both players have submitted their survey, proceed to the next stage
      history.push(`/endgamesurvey/${roomId}/${playerNumber}/?game=${gameNum}`, {
        members: members_1,
        currentPlayer: currentPlayer,
        finalGame: finalGame,
        gameNum: gameNum,
        gameEndQuestions: gameEndQuestions,
        messages: cardMessage
      });
    }
  }, [submittedPlayers, players]);

  // Handle survey form submission
  const handleSubmit = (event) => {
    const allQuestionsAnswered = Object.values(questions).every(
      (question) => question.a > 0 && question.b > 0
    );

    if (!allQuestionsAnswered) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // Submit the player's survey answers
      let ind = 1;
      let n = cardMessage.length;
      let request = {};

      while (ind < players + 1) {
        let question = `q${ind}`;
        let a = questions[question].a;
        let b = questions[question].b;

        request = {
          roomid: roomId,
          game_num: gameNum,
          round_num: round_1, 
          player_num: "Player " + playerNumber,
          card: cardMessage[n - 1].body.substring(0, 7),
          card_description: cardMessage[n - 1].description,
          q1_res: a,
          q2_res: b,
          game_end: false
        };

        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(request),
        };

        fetch(`${process.env.REACT_APP_API_URL}/survey`, options)
          .then(response => response.json())
          .then(data => console.log(data));

        ind++;
        n--;
      }

      sendSurveySubmit(submittedPlayers + 1); // Update the submitted count after survey submission
    }
  };

  const formGroups = [];
  let n = players;
  let z = cardMessage.length;
  let ctrl = 0;

  let flag = 0;
  
  formGroups.push(
    Object.keys(questions).map((questionKey) => {
      if (flag === n) return;
      flag += 1;
      z -= 1;
      
      return (
        <div key={questionKey}>
          <Row>
            <Card body style={{ width: '250px', left: '25%', height: '310px', backgroundColor: '#31a24c', color: "white", fontSize: '14px' }}>
              {cardMessage[z].body.substring(0, 7)}<br />{cardMessage[z].description}
            </Card>
          </Row>
          <Form.Label>Please enter your opinion about the card above</Form.Label>
          <Form.Group className="mb-3" controlId={"q" + (ctrl + 1)}>
            <Form.Label>{feedbackQuestions[0]}</Form.Label><br />
            <span style={{ display: "inline-block" }}>
              <span style={{ display: "inline-block" }}>0</span>
              <span style={{ display: "inline-block", margin: "10px" }}>
                <RangeSlider
                  value={questions[questionKey].a}
                  onChange={(e) =>
                    setquestions({
                      ...questions,
                      [questionKey]: {
                        ...questions[questionKey],
                        a: e.target.value,
                      },
                    })}
                />
              </span>
              <span style={{ display: "inline-block" }}>100</span>
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId={"q" + (ctrl + 2)}>
            <Form.Label>{feedbackQuestions[1]}</Form.Label><br />
            <span style={{ display: "inline-block" }}>
              <span style={{ display: "inline-block" }}>0</span>
              <span style={{ display: "inline-block", margin: "10px" }}>
                <RangeSlider
                  value={questions[questionKey].b}
                  onChange={(e) =>
                    setquestions({
                      ...questions,
                      [questionKey]: {
                        ...questions[questionKey],
                        b: e.target.value,
                      },
                    })}
                />
              </span>
              <span style={{ display: "inline-block" }}>100</span>
            </span>
          </Form.Group>
        </div>
      );
    })
  );
  n = n - 1;
  ctrl = ctrl + 1;

  return (
    <>
      {showSurvey && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <div className="center-div">
            <h3>Please share your opinion on the last round and the game</h3>
          </div>
          <div className="box">
            <Form>
              {formGroups}
              <div className="center-div">
                <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                  Submit Responses
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyPage;
