import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

// Message types
const NEW_CARD_MESSAGE_EVENT = "newCardMessage";
const ROUND_MESSAGE_EVENT = "roundMessage";
const NEW_JOIN_EVENT = "newJoin";
const GAME_END_EVENT = "gameEnd";
const SOCKET_DISCONNET_EVENT = "socketdisc";
const MID_GAME_EVENT = "midGame";
const ROOM_END_EVENT = "roomEnd";
const SURVEY_SUBMIT_EVENT = "surveySubmit";
const NEW_GAME_EVENT = "newGame";
const GAME_END_EVENT_DISCONNECT = "gameenddisconnect";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;

const useChat = (roomId, playerNumber) => {
  const [messages, setMessages] = useState([]);
  const [round, setRound] = useState(1);
  const [members, setMembers] = useState([]); // add a new state to check the survey form responses
  const [cardSentForRound, setCardSentForRound] = useState(0);
  const [gameEnd, setGameEnd] = useState(false);
  const [roomEnd, setRoomEnd] = useState(false);
  const [midGame, setMidGame] = useState(false);
  const [countofsurvey, setCountOfSubmit] = useState("");
  const socketRef = useRef();
  const [showSurvey, setShowSurvey] = useState(false);
  const [newGame, setNewGame] = useState(false);

  useEffect(() => {

    console.log(`Connecting to Socket.io server at ${SOCKET_SERVER_URL}`);
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId, playerNumber },
    });

    socketRef.current.on("connect", () => {
      console.log(`Connected to Socket.io server with ID ${socketRef.current.id}`);
      console.log("Current  :", members);
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from Socket.io server");
    });

    socketRef.current.on(NEW_CARD_MESSAGE_EVENT, (message) => {
      console.log("New card message received:", message);
      const incomingMessage = {
        ...message,
        ownedByCurrentUser: message.senderId === socketRef.current.id,
      };
      setMessages((messages) => [...messages, incomingMessage]);
      localStorage.setItem("messages_list", JSON.stringify(messages));
    });

    socketRef.current.on(ROUND_MESSAGE_EVENT, (round) => {
      console.log("Round message received:", round);
      setRound(round);
    });

    socketRef.current.on(MID_GAME_EVENT, () => {
      console.log("Mid-game event received");
      setMidGame(true);
    });

    socketRef.current.on(NEW_JOIN_EVENT, (memberInfoList) => {
      console.log("New join event received:", memberInfoList);
      setMembers(memberInfoList.map(member => ({
        ...member, 
        name: member.fullname || `Player ${member.number}`
      })));
      console.log("New join event updated:", memberInfoList);
    });

    socketRef.current.on(SURVEY_SUBMIT_EVENT, (count) => {
      console.log("Survey submit event received:", count);
      setCountOfSubmit(count);
      if (count === true) 
        setShowSurvey(true);
    });

    socketRef.current.on(GAME_END_EVENT, () => {
      console.log("Game end event received");
      setGameEnd(true);
    });

    socketRef.current.on(ROOM_END_EVENT, () => {
      console.log("Room end event received");
      setRoomEnd(true);
    });

    socketRef.current.on(NEW_GAME_EVENT, () => {
      console.log("New game event received");
      socketRef.current.disconnect();
    });

    return () => {
      console.log("Cleaning up Socket.io connection");
      socketRef.current.disconnect();
    };
  }, [roomId, socketRef, playerNumber]);

  useEffect(() => {
    console.log("Room members updated:", members);
  }, [members]);

  let player_number = "Unknown";
  for (var i = 0, l = members.length; i < l; i++) {
    if (members[i]["socketid"] === socketRef.current.id) {
      player_number = "Player " + members[i]["number"];
      break;
    }
  }

  const sendMessage = (
    messageBody,
    round,
    cardDescription,
    cardKeyword1,
    cardKeyword2,
    cardKeyword3
  ) => {
    if (cardSentForRound < round) {
      setCardSentForRound(round);
      let messsageJson = {
        card: messageBody,
        body: messageBody + " | sent by: " + player_number,
        description: cardDescription,
        cardKeyword1: cardKeyword1,
        cardKeyword2: cardKeyword2,
        cardKeyword3: cardKeyword3,
        senderId: socketRef.current.id,
        computerPlayer: false,
        playerNo: player_number,
      };
      console.log("Sending message:", messsageJson);
      socketRef.current.emit(NEW_CARD_MESSAGE_EVENT, messsageJson);
  
      return messsageJson;
    }
  };

  const sendSurveySubmit = (val) => {
    console.log("Sending survey submit event:", val);
    socketRef.current.emit(SURVEY_SUBMIT_EVENT, val);
    return val;
  };

  const sendSocketDisconnect = (val) => {
    console.log("Sending socket disconnect event:", val);
    socketRef.current.emit(NEW_GAME_EVENT, val);
  };

  return {
    sendSocketDisconnect,
    setMidGame,
    countofsurvey,
    sendSurveySubmit,
    messages,
    round,
    members,
    player_number,
    gameEnd,
    roomEnd,
    midGame,
    sendMessage,
    setMembers,
    setGameEnd,
    socketRef,
    showSurvey, 
    setShowSurvey,
  };
};

export default useChat;