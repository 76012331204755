import React, { useEffect, useState } from "react";
import { Form, Button, Row, Card } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import store from "store2";
import "./Survey.css";

const Survey = (props) => {
  const {
    setEndGame,
    setMidGame,
    sendSurveySubmit,
    setShowWaitPage,
    countofsurvey,
    setShowMidgame,
    setShowEndgame,
    setPrevRound,
    round,
    cardMessage,
    roomId,
    playerNumber,
    gameNum,
    feedbackQuestions,
    displayDelay,
    midgame,
    endgame,
    players,
  } = props;

  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState({
    q1: { a: 0, b: 0 },
    q2: { a: 0, b: 0 },
    q3: { a: 0, b: 0 },
    q4: { a: 0, b: 0 },
    q5: { a: 1, b: 1 },
    q6: { a: 1, b: 1 },
  });

  const [playerColorMapping, setPlayerColorMapping] = useState([]);

  useEffect(() => {
    const storedMapping = store.session.get("playerColorMapping");
    if (storedMapping) {
      setPlayerColorMapping(storedMapping);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), displayDelay);
    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [displayDelay]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const allQuestionsAnswered = Object.values(questions).every(
      (question) => question.a > 0 && question.b > 0
    );

    if (!allQuestionsAnswered) {
      alert("Please answer all questions before submitting.");
      return;
    }

    for (let i = 0; i < players; i++) {
      const questionKey = `q${i + 1}`;
      const a = questions[questionKey].a;
      const b = questions[questionKey].b;
      const card = cardMessage[cardMessage.length - 1 - i];

      const request = {
        roomid: roomId,
        game_num: gameNum,
        round_num: round,
        player_num: `Player ${playerNumber}`,
        card: card.body.substring(0, 7),
        card_description: card.description,
        q1_res: a,
        q2_res: b,
        game_end: false,
      };

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/survey`,
          options
        );
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error("Error submitting survey:", error);
      }
    }

    sendSurveySubmit(true);
    setShowWaitPage(true);

    if (midgame) {
      setShowMidgame(true);
      setMidGame(false);
    } else if (endgame) {
      setShowEndgame(true);
      setEndGame(false);
    } else {
      setPrevRound(round);
    }
  };

  const getPlayerColor = (playerNo) => {
    const playerMapping = playerColorMapping.find(
      (mapping) => mapping.player === playerNo
    );
    return playerMapping ? playerMapping.color : "#327957";
  };

  if (!show) return null;

  return (
    <div className="box">
      <div className="center-div">
        <h3>
          <b>Survey Questions on the cards played</b>
        </h3>
      </div>
      <Form onSubmit={handleSubmit}>
        {Object.keys(questions)
          .slice(0, players)
          .map((questionKey, index) => {
            const card = cardMessage[cardMessage.length - 1 - index];
            const playerColor = getPlayerColor(card.playerNo);
            return (
              <div key={questionKey} className="questionsContainer">
                <div className="cardContainer">
                  <div
                    className="card"
                    style={{ border: `0.5rem solid ${playerColor}` }}
                  >
                    {/* {card.body.substring(0, 7)} */}
                    {card.description}
                  </div>
                </div>
                <p className="text">
                  Please enter your opinion about the card above
                </p>
                <div className="sliders">
                  <Form.Group className="mb-3" controlId={questionKey}>
                    <li>
                      <Form.Label className="sliderQuestion">
                        {feedbackQuestions[0]}
                      </Form.Label>
                    </li>
                    <div className="sliderContainer">
                      <p className="sliderNumber">0</p>
                      <RangeSlider
                        value={questions[questionKey].a}
                        onChange={(e) =>
                          setQuestions({
                            ...questions,
                            [questionKey]: {
                              ...questions[questionKey],
                              a: Number(e.target.value),
                            },
                          })
                        }
                      />
                      <p className="sliderNumber">100</p>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId={`${questionKey}b`}>
                    <li>
                      <Form.Label className="sliderQuestion">
                        {feedbackQuestions[1]}
                      </Form.Label>
                    </li>
                    <div className="sliderContainer">
                      <p className="sliderNumber">0</p>
                      <RangeSlider
                        value={questions[questionKey].b}
                        onChange={(e) =>
                          setQuestions({
                            ...questions,
                            [questionKey]: {
                              ...questions[questionKey],
                              b: Number(e.target.value),
                            },
                          })
                        }
                      />
                      <p className="sliderNumber">100</p>
                    </div>
                  </Form.Group>
                </div>
              </div>
            );
          })}
        <div className="buttonContainer">
          <Button variant="primary" type="submit" className="primaryButton">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Survey;

