import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./Cognitive1.css";

const Cognitive2 = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;

  const [ validated, setValidated ] = useState(false);

  const [ q1, setQ1 ] = React.useState("");
  const [ q2, setQ2 ] = React.useState("");
  const [ q3, setQ3 ] = React.useState("");
  const [ q4, setQ4 ] = React.useState("");
  const [ q5, setQ5 ] = React.useState("");
  const [ q6, setQ6 ] = React.useState("");
  const [ q7, setQ7 ] = React.useState("");
  const [ q8, setQ8 ] = React.useState("");
  const [ q9, setQ9 ] = React.useState("");
  const [ q10, setQ10 ] = React.useState("");
  const [ q11, setQ11 ] = React.useState("");
  const [ q12, setQ12 ] = React.useState("");
  const [ q13, setQ13 ] = React.useState("");
  const [ q14, setQ14 ] = React.useState("");
  const [ q15, setQ15 ] = React.useState("");



  const handleSubmit = (event) => {

    event.preventDefault();

    if (
      !q1 || !q2 || !q3 || !q4 || !q5 ||
      !q6 || !q7 || !q8 || !q9 || !q10 ||
      !q11 || !q12 || !q13 || !q14 || !q15 
    ) {
      alert("Please fill out all required fields before submitting.");
      return;
    }


    console.log("Cognitive form Survey form submitted")
    let request = {
      "roomid": roomId,
      "player_num": "Player " + playerNumber,
      "q1": q1,
      "q2": q2,
      "q3": q3,
      "q4": q4,
      "q5": q5,
      "q6": q6,
      "q7": q7,
      "q8": q8,
      "q9": q9,
      "q10": q10,
      "q11": q11,
      "q12": q12,
      "q13": q13,
      "q14": q14,
      "q15": q15,
    };
    
    console.log(request);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    fetch(`${process.env.REACT_APP_API_URL}/cognitive2`, options)
      .then(response => response.json())
      .then(data => console.log(data));

    console.log(request);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

    }
    setValidated(true);
    history.push(`/cog3instructions/${roomId}/${playerNumber}`);
  };

  return (
    <div className="demo-container">
      <h2> Conginitive Questionnaire 2</h2>

      <br />
      <h4>Please answer the following questions:</h4>
      <Form.Label>Find the odd one out.</Form.Label><br />
      <Form className="formgrid">
        <Form.Group className="mb-3" controlId="q1">
        <Form.Label>1.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="QPPQ"  type='radio'  checked={q1 === "1"} onChange={e => setQ1(e.target.value)} required/>
               <Form.Check value="2" label="HGHH"  type='radio'  checked={q1 === "2"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="3" label="TTTU"  type='radio'  checked={q1 === "3"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="4" label="DDDE"  type='radio'  checked={q1 === "4"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="5" label="MLMM"  type='radio'  checked={q1 === "5"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q1 === "6"} onChange={e => setQ1(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q2">
        <Form.Label>2.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BCDE"  type='radio'  checked={q2 === "1"} onChange={e => setQ2(e.target.value)} required/>
               <Form.Check value="2" label="FGHI"  type='radio'  checked={q2 === "2"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="3" label="JKLM"  type='radio'  checked={q2 === "3"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="4" label="PRST"  type='radio'  checked={q2 === "4"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="5" label="VWXY"  type='radio'  checked={q2 === "5"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q2 === "6"} onChange={e => setQ2(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q3">
        <Form.Label>3.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BVZC"  type='radio'  checked={q3 === "1"} onChange={e => setQ3(e.target.value)} required/>
               <Form.Check value="2" label="FVZG"  type='radio'  checked={q3 === "2"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="3" label="JVZK"  type='radio'  checked={q3 === "3"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="4" label="PWXQ"  type='radio'  checked={q3 === "4"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="5" label="SVZT"  type='radio'  checked={q3 === "5"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q3 === "6"} onChange={e => setQ3(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q4">
        <Form.Label>4.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BCEF"  type='radio'  checked={q4 === "1"} onChange={e => setQ4(e.target.value)} required/>
               <Form.Check value="2" label="FGIJ"  type='radio'  checked={q4 === "2"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="3" label="STWX"  type='radio'  checked={q4 === "3"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="4" label="CDFG"  type='radio'  checked={q4 === "4"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="5" label="PQST"  type='radio'  checked={q4 === "5"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q4 === "6"} onChange={e => setQ4(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q5">
        <Form.Label>5.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BCCB"  type='radio'  checked={q5 === "1"} onChange={e => setQ5(e.target.value)} required/>
               <Form.Check value="2" label="GFFG"  type='radio'  checked={q5 === "2"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="3" label="LMML"  type='radio'  checked={q5 === "3"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="4" label="QRRQ"  type='radio'  checked={q5 === "4"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="5" label="WXXW"  type='radio'  checked={q5 === "5"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q5 === "6"} onChange={e => setQ5(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q6">
        <Form.Label>6.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="AAPP"  type='radio'  checked={q6 === "1"} onChange={e => setQ6(e.target.value)} required/>
               <Form.Check value="2" label="CCRR"  type='radio'  checked={q6 === "2"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="3" label="QQBB"  type='radio'  checked={q6 === "3"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="4" label="EETT"  type='radio'  checked={q6 === "4"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="5" label="DDSS"  type='radio'  checked={q6 === "5"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q6 === "6"} onChange={e => setQ6(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q7">
        <Form.Label>7.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="ABDC"  type='radio'  checked={q7 === "1"} onChange={e => setQ7(e.target.value)} required/>
               <Form.Check value="2" label="EGFH"  type='radio'  checked={q7 === "2"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="3" label="IJLK"  type='radio'  checked={q7 === "3"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="4" label="OPRQ"  type='radio'  checked={q7 === "4"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="5" label="UVXW"  type='radio'  checked={q7 === "5"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q7 === "6"} onChange={e => setQ7(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q8">
        <Form.Label>8.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="CERT"  type='radio'  checked={q8 === "1"} onChange={e => setQ8(e.target.value)} required/>
               <Form.Check value="2" label="KMTV"  type='radio'  checked={q8 === "2"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="3" label="FHXZ"  type='radio'  checked={q8 === "3"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="4" label="BODQ"  type='radio'  checked={q8 === "4"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="5" label="HJPR"  type='radio'  checked={q8 === "5"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q8 === "6"} onChange={e => setQ8(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q9">
        <Form.Label>9.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="PABQ"  type='radio'  checked={q9 === "1"} onChange={e => setQ9(e.target.value)} required/>
               <Form.Check value="2" label="SEFT"  type='radio'  checked={q9 === "2"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="3" label="VIJW"  type='radio'  checked={q9 === "3"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="4" label="COPD"  type='radio'  checked={q9 === "4"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="5" label="FUZG"  type='radio'  checked={q9 === "5"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q9 === "6"} onChange={e => setQ9(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q10">
        <Form.Label>10.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="CFCR"  type='radio'  checked={q10 === "1"} onChange={e => setQ10(e.target.value)} required/>
               <Form.Check value="2" label="JCVC"  type='radio'  checked={q10 === "2"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="3" label="CGCS"  type='radio'  checked={q10 === "3"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="4" label="CLXC"  type='radio'  checked={q10 === "4"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="5" label="KCWC"  type='radio'  checked={q10 === "5"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q10 === "6"} onChange={e => setQ10(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q11">
        <Form.Label>11.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="XDBK"  type='radio'  checked={q11 === "1"} onChange={e => setQ11(e.target.value)} required/>
               <Form.Check value="2" label="TNLL"  type='radio'  checked={q11 === "2"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="3" label="VEGV"  type='radio'  checked={q11 === "3"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="4" label="PFCC"  type='radio'  checked={q11 === "4"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="5" label="ZAGZ"  type='radio'  checked={q11 === "5"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q11 === "6"} onChange={e => setQ11(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q12">
        <Form.Label>12.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="CAEZ"  type='radio'  checked={q12 === "1"} onChange={e => setQ12(e.target.value)} required/>
               <Form.Check value="2" label="CEIZ"  type='radio'  checked={q12 === "2"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="3" label="CIOZ"  type='radio'  checked={q12 === "3"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="4" label="CGVZ"  type='radio'  checked={q12 === "4"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="5" label="CAUZ"  type='radio'  checked={q12 === "5"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q12 === "6"} onChange={e => setQ12(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q13">
        <Form.Label>13.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="VEBT"  type='radio'  checked={q13 === "1"} onChange={e => setQ13(e.target.value)} required/>
               <Form.Check value="2" label="XGDV"  type='radio'  checked={q13 === "2"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="3" label="ZIFX"  type='radio'  checked={q13 === "3"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="4" label="KXVH"  type='radio'  checked={q13 === "4"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="5" label="MZXJ"  type='radio'  checked={q13 === "5"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q13 === "6"} onChange={e => setQ13(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q14">
        <Form.Label>14.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="AFBG"  type='radio'  checked={q14 === "1"} onChange={e => setQ14(e.target.value)} required/>
               <Form.Check value="2" label="EJFK"  type='radio'  checked={q14 === "2"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="3" label="GKHM"  type='radio'  checked={q14 === "3"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="4" label="PSQT"  type='radio'  checked={q14 === "4"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="5" label="RWSX"  type='radio'  checked={q14 === "5"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q14 === "6"} onChange={e => setQ14(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q15">
        <Form.Label>15.</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="KGDB"  type='radio'  checked={q15 === "1"} onChange={e => setQ15(e.target.value)} required/>
               <Form.Check value="2" label="DFIM"  type='radio'  checked={q15 === "2"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="3" label="KIFB"  type='radio'  checked={q15 === "3"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="4" label="HJMQ"  type='radio'  checked={q15 === "4"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="5" label="LHEC"  type='radio'  checked={q15 === "5"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q15 === "6"} onChange={e => setQ15(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
      </Form>
      <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
    </div >
  );
};

export default Cognitive2;
