import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import CardConfig from "../CardConfig.json";
import "./MidGameSurvey.css";

const MidGameSurvey = ({
  setShowMidgame,
  setPrevroundAndResetTimer,
  round,
  roomId,
  playerNumber,
  gameNum,
  midGameQuestions,
  displayDelay,
}) => {
  const [show, setShow] = useState(false);
  const [responses, setResponses] = useState(Array(5).fill("")); // Ensure array length matches number of questions
  // const cards = CardConfig.cards;

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), displayDelay);
    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [displayDelay]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (responses.includes("")) {
      alert("Please answer all questions before submitting.");
      return;
    }

    console.log("Mid game Survey form submitted");

    const request = {
      roomid: roomId,
      game_num: gameNum,
      round_num: round,
      player_num: `Player ${playerNumber}`,
      q1_res: responses[0],
      q2_res: responses[1],
      q3_res: responses[2],
      q4_res: responses[3],
      q5_res: responses[4],
      game_end: false,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    };

    fetch(`${process.env.REACT_APP_API_URL}/midgamesurvey`, options)
      .then((response) => response.json())
      .then((data) => console.log(data));

    setShowMidgame(false);
    setPrevroundAndResetTimer(round);
  };

  const handleResponseChange = (questionIndex, value) => {
    const newResponses = [...responses];
    newResponses[questionIndex] = value;
    setResponses(newResponses);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="box">
      <Form onSubmit={handleSubmit}>
        <div className="center-div">
          <h3>
            <b>Mid Game Survey</b>
          </h3>
        </div>
        {midGameQuestions.slice(0, 5).map((question, index) => (
          <Form.Group key={index} className="mb-3" controlId={`q${index + 1}`}>
            <Form.Label>{question}</Form.Label>
            <br />
            <span style={{ display: "inline-block" }}>
              <span style={{ display: "inline-block", margin: "10px" }}>
                {midGameQuestions[5 + index]
                  .split(",")
                  .map((option, optIndex) => (
                    <Form.Check
                      inline
                      key={optIndex}
                      value={option}
                      label={option}
                      type="radio"
                      checked={responses[index] === option}
                      onChange={(e) =>
                        handleResponseChange(index, e.target.value)
                      }
                    />
                  ))}
              </span>
            </span>
          </Form.Group>
        ))}
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default MidGameSurvey;
