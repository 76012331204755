import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./Cognitive1.css";

const Cognitive = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;
  const [ validated, setValidated ] = useState(false);

  const [ q1, setQ1 ] = React.useState("");
  const [ q2, setQ2 ] = React.useState("");
  const [ q3, setQ3 ] = React.useState("");
  const [ q4, setQ4 ] = React.useState("");
  const [ q5, setQ5 ] = React.useState("");
  const [ q6, setQ6 ] = React.useState("");
  const [ q7, setQ7 ] = React.useState("");
  const [ q8, setQ8 ] = React.useState("");
  const [ q9, setQ9 ] = React.useState("");
  const [ q10, setQ10 ] = React.useState("");
  const [ q11, setQ11 ] = React.useState("");
  const [ q12, setQ12 ] = React.useState("");
  const [ q13, setQ13 ] = React.useState("");
  const [ q14, setQ14 ] = React.useState("");
  const [ q15, setQ15 ] = React.useState("");
  const [ q16, setQ16 ] = React.useState("");
  const [ q17, setQ17 ] = React.useState("");
  const [ q18, setQ18 ] = React.useState("");



  const handleSubmit = (event) => {
    event.preventDefault();
    // check if all questions are answered
    if (
      !q1 || !q2 || !q3 || !q4 || !q5 ||
      !q6 || !q7 || !q8 || !q9 || !q10 ||
      !q11 || !q12 || !q13 || !q14 || !q15 ||
      !q16 || !q17 || !q18
    ) {
      alert("Please fill out all required fields before submitting.");
      return;
    }


    
    console.log("Cognitive form Survey form submitted")
    let request = {
      "roomid": roomId,
      "player_num": "Player " + playerNumber,
      "q1": q1,
      "q2": q2,
      "q3": q3,
      "q4": q4,
      "q5": q5,
      "q6": q6,
      "q7": q7,
      "q8": q8,
      "q9": q9,
      "q10": q10,
      "q11": q11,
      "q12": q12,
      "q13": q13,
      "q14": q14,
      "q15": q15,
    };
    
    console.log(request);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    fetch(`${process.env.REACT_APP_API_URL}/cognitive1`, options)
      .then(response => response.json())
      .then(data => console.log(data));

    console.log(request);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

    }
    setValidated(true);
    history.push(`/cog2instructions/${roomId}/${playerNumber}`);
  };

  return (
    <div className="demo-container">
      <h2> Conginitive Questionnaire 1</h2>

      <br />
      <h3>Please answer the following questions:</h3><br />
      <h2>Questions</h2>
      <Form className="formgrid">
        <Form.Group className="mb-3" controlId="q1">
          <Form.Label> a. mumble</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-speak indistinctly"  type='radio'  checked={q1 === "1"} onChange={e => setQ1(e.target.value)} required/>
               <Form.Check value="2" label="2-complain"  type='radio'  checked={q1 === "2"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="3" label="3-handle awkwardly"  type='radio'  checked={q1 === "3"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="4" label="4- fall  over something"  type='radio'  checked={q1 === "4"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="5" label="5-tear apart"  type='radio'  checked={q1 === "5"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q1 === "6"} onChange={e => setQ1(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q2">
          <Form.Label>b. perspire</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-struggle"  type='radio'  checked={q2 === "1"} onChange={e => setQ2(e.target.value)} required/>
               <Form.Check value="2" label="2-sweat"  type='radio'  checked={q2 === "2"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="3" label="3-happen"  type='radio'  checked={q2 === "3"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="4" label="4-penetrate"  type='radio'  checked={q2 === "4"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="5" label="5-submit"  type='radio'  checked={q2 === "5"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q2 === "6"} onChange={e => setQ2(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q3">
          <Form.Label>c. gush</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-giggle"  type='radio'  checked={q3 === "1"} onChange={e => setQ3(e.target.value)} required/>
               <Form.Check value="2" label="2-spout"  type='radio'  checked={q3 === "2"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="3" label="3-sprinkle"  type='radio'  checked={q3 === "3"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="4" label="4-hurry"  type='radio'  checked={q3 === "4"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="5" label="5-cry"  type='radio'  checked={q3 === "5"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q3 === "6"} onChange={e => setQ3(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q4">
          <Form.Label>d. massive</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-strong and muscular"  type='radio'  checked={q4 === "1"} onChange={e => setQ4(e.target.value)} required/>
               <Form.Check value="2" label="2-thickly populated"  type='radio'  checked={q4 === "2"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="3" label="3-ugly and awkward"  type='radio'  checked={q4 === "3"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="4" label="4-huge and solid"  type='radio'  checked={q4 === "4"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="5" label="5-everlasting"  type='radio'  checked={q4 === "5"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q4 === "6"} onChange={e => setQ4(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q5">
          <Form.Label>e. feign</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-pretend"  type='radio'  checked={q5 === "1"} onChange={e => setQ5(e.target.value)} required/>
               <Form.Check value="2" label="2-prefer"  type='radio'  checked={q5 === "2"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="3" label="3-wear"  type='radio'  checked={q5 === "3"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="4" label="4-be cautious"  type='radio'  checked={q5 === "4"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="5" label="5-surrender"  type='radio'  checked={q5 === "5"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q5 === "6"} onChange={e => setQ5(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q6">
          <Form.Label>f. unwary</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-unusual"  type='radio'  checked={q6 === "1"} onChange={e => setQ6(e.target.value)} required/>
               <Form.Check value="2" label="2-deserted"  type='radio'  checked={q6 === "2"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="3" label="3-incautious"  type='radio'  checked={q6 === "3"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="4" label="4-sudden"  type='radio'  checked={q6 === "4"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="5" label="5-tireless"  type='radio'  checked={q6 === "5"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q6 === "6"} onChange={e => setQ6(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q7">
          <Form.Label>g. veer</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-change directiony"  type='radio'  checked={q7 === "1"} onChange={e => setQ7(e.target.value)} required/>
               <Form.Check value="2" label="2-hesitate"  type='radio'  checked={q7 === "2"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="3" label="3-catch sight of"  type='radio'  checked={q7 === "3"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="4" label="4-cover with a thin layer"  type='radio'  checked={q7 === "4"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="5" label="5-slide"  type='radio'  checked={q7 === "5"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q7 === "6"} onChange={e => setQ7(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q8">
          <Form.Label>h. orthodox</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-conventional"  type='radio'  checked={q8 === "1"} onChange={e => setQ8(e.target.value)} required/>
               <Form.Check value="2" label="2-straight"  type='radio'  checked={q8 === "2"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="3" label="3-surgical"  type='radio'  checked={q8 === "3"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="4" label="4-right-angled"  type='radio'  checked={q8 === "4"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="5" label="5-religious"  type='radio'  checked={q8 === "5"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q8 === "6"} onChange={e => setQ8(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q9">
          <Form.Label>i. stripling</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-stream"  type='radio'  checked={q9 === "1"} onChange={e => setQ9(e.target.value)} required/>
               <Form.Check value="2" label="2-narrow path"  type='radio'  checked={q9 === "2"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="3" label="3-engraving"  type='radio'  checked={q9 === "3"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="4" label="4-lad"  type='radio'  checked={q9 === "4"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="5" label="5-tear apart"  type='radio'  checked={q9 === "5"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q9 === "6"} onChange={e => setQ9(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q10">
          <Form.Label>j. salubrious</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-mirthful"  type='radio'  checked={q10 === "1"} onChange={e => setQ10(e.target.value)} required/>
               <Form.Check value="2" label="2-indecent"  type='radio'  checked={q10 === "2"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="3" label="3-salty"  type='radio'  checked={q10 === "3"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="4" label="4-mournful"  type='radio'  checked={q10 === "4"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="5" label="5-healthful"  type='radio'  checked={q10 === "5"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q10 === "6"} onChange={e => setQ10(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q11">
          <Form.Label>k. limpid</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-lazy"  type='radio'  checked={q11 === "1"} onChange={e => setQ11(e.target.value)} required/>
               <Form.Check value="2" label="2-crippled"  type='radio'  checked={q11 === "2"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="3" label="3-clear"  type='radio'  checked={q11 === "3"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="4" label="4-hot"  type='radio'  checked={q11 === "4"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="5" label="5-slippery"  type='radio'  checked={q11 === "5"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q11 === "6"} onChange={e => setQ11(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q12">
          <Form.Label>l. procreate</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-sketch"  type='radio'  checked={q12 === "1"} onChange={e => setQ12(e.target.value)} required/>
               <Form.Check value="2" label="2-inhabit"  type='radio'  checked={q12 === "2"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="3" label="3-imitate"  type='radio'  checked={q12 === "3"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="4" label="4-beget"  type='radio'  checked={q12 === "4"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="5" label="5-encourage"  type='radio'  checked={q12 === "5"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q12 === "6"} onChange={e => setQ12(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q13">
          <Form.Label>m. replete</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-full"  type='radio'  checked={q13 === "1"} onChange={e => setQ13(e.target.value)} required/>
               <Form.Check value="2" label="2-elderly"  type='radio'  checked={q13 === "2"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="3" label="3-resentful"  type='radio'  checked={q13 === "3"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="4" label="4-discredited"  type='radio'  checked={q13 === "4"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="5" label="5-restful"  type='radio'  checked={q13 === "5"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q13 === "6"} onChange={e => setQ13(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q14">
          <Form.Label>n. frieze</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-fringe of curls on the forehead"  type='radio'  checked={q14 === "1"} onChange={e => setQ14(e.target.value)} required/>
               <Form.Check value="2" label="2-statue"  type='radio'  checked={q14 === "2"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="3" label="3-ornamental band"  type='radio'  checked={q14 === "3"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="4" label="4-discredited"  type='radio'  checked={q14 === "4"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="5" label="5-restful"  type='radio'  checked={q14 === "5"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q14 === "6"} onChange={e => setQ14(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q15">
          <Form.Label>o. treacle</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-sewing machine"  type='radio'  checked={q15 === "1"} onChange={e => setQ15(e.target.value)} required/>
               <Form.Check value="2" label="2-framework"  type='radio'  checked={q15 === "2"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="3" label="3-leak"  type='radio'  checked={q15 === "3"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="4" label="4-apple butter"  type='radio'  checked={q15 === "4"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="5" label="5-molasses"  type='radio'  checked={q15 === "5"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q15 === "6"} onChange={e => setQ15(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q16">
          <Form.Label>p. ignominious</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-inflammable"  type='radio'  checked={q16 === "1"} onChange={e => setQ16(e.target.value)} required/>
               <Form.Check value="2" label="2-elflike"  type='radio'  checked={q16 === "2"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="3" label="3-unintelligent"  type='radio'  checked={q16 === "3"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="4" label="4-disgraceful"  type='radio'  checked={q16 === "4"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="5" label="5-mysterious"  type='radio'  checked={q16 === "5"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q16 === "6"} onChange={e => setQ16(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q17">
          <Form.Label>q. abjure</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-make certain"  type='radio'  checked={q17 === "1"} onChange={e => setQ17(e.target.value)} required/>
               <Form.Check value="2" label="2-arrest"  type='radio'  checked={q17 === "2"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="3" label="3-renounce"  type='radio'  checked={q17 === "3"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="4" label="4-abuse"  type='radio'  checked={q17 === "4"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="5" label="5-lose"  type='radio'  checked={q17 === "5"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q17 === "6"} onChange={e => setQ17(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q18">
          <Form.Label>r. duress</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-period of time"  type='radio'  checked={q18 === "1"} onChange={e => setQ18(e.target.value)} required/>
               <Form.Check value="2" label="2-distaste"  type='radio'  checked={q18 === "2"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="3" label="3-courage"  type='radio'  checked={q18 === "3e"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="4" label="4-hardness"  type='radio'  checked={q18 === "4"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="5" label="5-compulsion"  type='radio'  checked={q18 === "5"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q18 === "6"} onChange={e => setQ18(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
      </Form>
      <Button style={{marginLeft: "50%"}} variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
    </div >
  );
};

export default Cognitive;
