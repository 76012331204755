import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import store from "store2";
import "./EndgameSurvey.css";

const EndgameSurvey = ({
  setFinalDecision,
  setShowEndgameSurvey,
  setShowEndgame,
  roomId,
  sendSurveySubmit,
  setShowWaitPage,
  countofsurvey,
  members,
  currentPlayer,
  gameNum,
  highestavg_card,
  gameEndQuestions,
  messages,
  displayDelay,
}) => {
  // let history = useHistory();
  const [playerName, setPlayerName] = useState("");
  const [show, setShow] = useState(false);
  const [responses, setResponses] = useState({
    geq1: null,
    q1: null,
    q2: null,
    q3: null,
  });

  const [playerColorMapping, setPlayerColorMapping] = useState([]);

  useEffect(() => {
    const storedMapping = store.session.get("playerColorMapping");
    if (storedMapping) {
      setPlayerColorMapping(storedMapping);
    }
  }, []);

  useEffect(() => {
    const currentPlayerInfo = members.find(member => member.number === currentPlayer);
    if (currentPlayerInfo) {
      setPlayerName(currentPlayerInfo.fullname || `Player ${currentPlayerInfo.number}`);
    }
  }, [members, currentPlayer]);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), displayDelay);
    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [displayDelay]);

  const handleResponseChange = (name, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { geq1, q1, q2, q3 } = responses;

    if ([geq1, q1, q2, q3].includes(null)) {
      alert("Please answer all questions before submitting.");
      return;
    }

    const request = {
      roomid: roomId,
      game_num: gameNum,
      player_num: `Player ${currentPlayer}`,
      q1_res: geq1,
      p1_rating: q1,
      p2_rating: q2,
      p3_rating: q3,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    };

    fetch(`${process.env.REACT_APP_API_URL}/finaldecision`, options)
      .then((response) => response.json())
      .then((data) => console.log(data));

    setShowEndgame(false);
    setShowEndgameSurvey(false);
    setFinalDecision(true);
    sendSurveySubmit(true);
    setShowWaitPage(true);
  };

  const getPlayerColor = (playerNo) => {
    const playerMapping = playerColorMapping.find(
      (mapping) => mapping.player === playerNo
    );
    return playerMapping ? playerMapping.color : "transparent";
  };

  if (!show) return null;

  const filteredMembers = members.filter(
    (member) => member.number !== currentPlayer
  );

  return (
    <div className="popup-box">
      <div className="box">
        <h3>
          <b>Please share your final decision about the game</b>
        </h3>
        <p className="highestRatedCardText">
          The highest rated card in the final round is: <b>{highestavg_card}</b>
        </p>
        <div className="card-container">
          <p className="cardContainerHeading">
            The cards played by all the players are:
          </p>
          {Object.entries(
            messages.reduce((grouped, message) => {
              (grouped[message.playerNo] =
                grouped[message.playerNo] || []).push(message);
              return grouped;
            }, {})
          ).map(([playerNo, playerMessages]) => (
            <div key={playerNo} className="playerGrid">
              <p className="playerNumberText">{"Player Name: " + playerName}</p>
              <div className="card-grid">
                {playerMessages?.map((message) => (
                  <div
                    className="card"
                    key={message.card}
                    style={{ border: `0.5rem solid ${getPlayerColor(playerNo)}` }}
                  >
                    <p className="cardTextName">{message.card}</p>
                    <p className="cardTextBody">{message.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="form">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="geq1">
              <Form.Label>
                <b>{gameEndQuestions[0]}</b>
              </Form.Label>
              <br />
              {gameEndQuestions[1].split(",").map((option, index) => (
                <Form.Check
                  inline
                  key={index}
                  value={option}
                  label={option}
                  type="radio"
                  checked={responses.geq1 === option}
                  onChange={(e) => handleResponseChange("geq1", e.target.value)}
                />
              ))}
            </Form.Group>
            {filteredMembers.map((member, index) => (
              <div key={index} className="formGroup">
                <Form.Group
                  key={index}
                  className="mb-3"
                  controlId={`q${index + 1}`}
                >
                  <Form.Label>
                    <b>
                      What do you think about the helpfulness of the cards
                      presented by {member.number}?
                    </b>
                  </Form.Label>
                  <br />
                  {[
                    "Very Unhelpful",
                    "Unhelpful",
                    "Neutral",
                    "Helpful",
                    "Very Helpful",
                  ].map((option, optIndex) => (
                    <Form.Check
                      inline
                      key={optIndex}
                      value={option}
                      label={option}
                      type="radio"
                      checked={responses[`q${index + 1}`] === option}
                      onChange={(e) =>
                        handleResponseChange(`q${index + 1}`, e.target.value)
                      }
                    />
                  ))}
                </Form.Group>
              </div>
            ))}
            <div className="buttonContainer">
              <Button variant="primary" type="submit" className="primaryButton">
                Submit Responses
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EndgameSurvey;
