import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import "./FinalDecision.css";

const FinalDecision = (props) => {
  let history = useHistory();

  let {
    setNewGame,
    sendSocketDisconnect,
    setFinalDecision,
    roomId,
    members,
    currentPlayer,
    gameNum,
    finalGame,
    final,
  } = props;

  console.log("Is it a final game?: " + finalGame);
  console.log("Is final 0??: " + final);

  // const [showFinal, setShowFinal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // try to remove

    if (!finalGame) {
      setFinalDecision(false);
      sendSocketDisconnect(true);
      //Jan30
      setNewGame(true);
      //history.push(`/room/${roomId}/${currentPlayer}?game=${Number(gameNum) + 1}`);
    } else {
      console.log("final game " + gameNum);
      setFinalDecision(false);
      sendSocketDisconnect(true);
      history.push(`/reflections/${roomId}/${currentPlayer}`, {
        members: members,
        currentPlayer: currentPlayer,
      }); // TODO
    }
  };

  if (final !== 0) {
    return (
      <div className="popup-box">
        <div className="box">
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <div className="center-div">
              <h3>Here is the group consensus of the game</h3>
              <Form>
                <Form.Label>Final decision of your game is: {final}</Form.Label>
              </Form>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Proceed to next game
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="popup-box">
        <div className="box">
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <div className="center-div">
              <h3>Here is the group consensus of the game</h3>

              <Form>
                <Form.Label>
                  Your group does not come up with a coherent decision. This
                  problem is not solved.{" "}
                </Form.Label>
              </Form>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Proceed to next game
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
    //}
  }
};

export default FinalDecision;
