import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import "./Cog3_Instructions.css";

const Cog3_Instructions = (props) => {
  const [ moveToLobby, setMoveToLobby ] = useState(false);
  const { roomId, playerNumber } = props.match.params;

  const handleJoinButtonClick = () => {
    setMoveToLobby(true);
  };

  return (
    <div className="main-container">
      <div>
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '35px' }}>Vocabulary (Advanced Vocabulary Task) - 3</p>
        <br />
        <p>
        For each of the statements below, please indicate to what extent the <br/>
        statement is characteristic of you. If the statement is extremely uncharacteristic of you (not <br/>
        at all like you) please write a "1" to the left of the question; if the statement is extremely <br/>
        characteristic of you (very much like you) please write a "5" next to the question. Of course, <br/>
        a statement may be neither extremely uncharacteristic nor extremely characteristic of you; <br/>
        if so, please use the number in the middle of the scale that describes the best fit. Please <br/>
        keep the following scale in mind as you rate each of the statements below: <br/>
        1 = extremely uncharacteristic; <br/>
        2 = somewhat uncharacteristic; <br/>
        3 = uncertain; <br/>
        4 = somewhat characteristic; <br/>
        5 = extremely characteristic. <br/>
        <br /><br />
        </p>
        <br />
      </div>
      <div style={{margin: 'auto'}}>
        <button className="enter-room-button" onClick={handleJoinButtonClick}>
          Start Game
        </button>
      </div>
      {moveToLobby &&
        <Redirect to={`/cognitive3/${roomId}/${playerNumber}`} />
      }
    </div>
  );
};

export default Cog3_Instructions;
