import React from "react";

import "./EndPage.css";

const EndPage = () => {
  const reward_code = "C1GG3ORD";

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(reward_code).then(() => {
      alert("Reward code copied to clipboard!");
    }, (err) => {
      console.error("Failed to copy text: ", err);
    });
  };

  return (
    <div className="home-container">
      <h2>Thank you very much participating in the study. We are grateful for your contributions to the research. 
Please copy this reward code “C1GG3ORD” back to the site to claim the completion of this task. The compensation will be transferred to your account once confirming your response. Thank you very much.
      </h2>
      <div class="button-container">
        <a href="https://www.google.co.uk/">
          <button>Visit Site</button>
        </a>
        <br></br>
        <button onClick={copyToClipBoard}>Click To Copy Reward Code</button>
      </div>
    </div>
  );
};

export default EndPage;
