import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import "./NewGame.css";

const NewGame = (props) => {
  const [ moveToLobby, setMoveToLobby ] = useState(false);
  const { roomId, playerNumber} = props.match.params
  const location = useLocation(); 
  const searchParams = new URLSearchParams(location.search);
  let gameNum = parseInt(searchParams.get('game'));

  const handleJoinButtonClick = () => {
    setMoveToLobby(true);
  };

  const totalGames = 3;
  const gamesRemain = totalGames - gameNum;

  return (
    <div className="main-container">
      <div>
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '35px' }}>If you are ready, please press Next to enter the new game.</p>
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '35px' }}>You have {gamesRemain} game{gamesRemain === 1 ? " ": "s "} left</p>
      </div >
        <button className="enter-room-button" onClick={handleJoinButtonClick}>
          Next Game
        </button>
      {moveToLobby &&
        <Redirect to={`/room/${roomId}/${playerNumber}?game=${Number(gameNum) + 1}`} />
      }
    </div>
  );
};

export default NewGame;
