import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card as BootstrapCard } from "react-bootstrap";
import Countdown, { zeroPad, CountdownApi } from "react-countdown";
import store from "store2";

import useChat from "../useChat";
import Card from "../Card/Card.jsx";
import Survey from "../Survey/Survey";
import EndgameSurvey from "../EndgameSurvey/EndgameSurvey"; //11OCT
import Game from "../GameDescription/Game";

import MidGameSurvey from "../MidGameSurvey/MidGameSurvey";

import "./Room.css";
import FinalDecision from "../FinalDecision/FinalDecision";

import ModalComponent from "../ModalComponent/ModalComponent";
import CardModal from "../CardModal/CardModal";

const DEFAULT_CONFIG = {
  PLAYER_LIMIT_PER_ROOM: 4,
  ROUND_LIMIT: 6,
  GAME_LIMIT: 3,
  COUNTDOWN_DURATION: 180000,
  DISPLAY_SURVEY_DELAY: 5000,
};
const COUNTDOWN_DURATION = 180000; // milliseconds
const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  return (
    <span>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
};

const Room = (props) => {
  let history = useHistory();

  let gameText = window.location.href.match(/game=\d/)[0];
  const gameNum = gameText.match(/\d/)[0];

  const { roomId, playerNumber } = props.match.params;
  const {
    sendSocketDisconnect,
    setMidGame,
    countofsurvey,
    sendSurveySubmit,
    messages,
    round,
    members,
    player_number,
    gameEnd,
    roomEnd,
    midGame,
    sendMessage,
    setMembers,
    setGameEnd,
    socketRef,
  } = useChat(roomId, playerNumber);
  //console.log("As soon as we enter Room.jsx "+ JSON.stringify(members))
  const [midGameQuestions, setMidGameQuestions] = useState([]);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [gameEndQuestions, setGameEndQuestions] = useState([]);
  const [gameDescription, setGameDescription] = useState(""); // changes the state from object to string
  const [showGameScenarioModal, setShowGameScenarioModal] = useState(true);
  const [isGameScenarioModalClosed, setIsGameScenarioModalClosed] =
    useState(false);
  const [isTextHidden, setTextHidden] = useState(true);
  const [ismidgame, setShowMidgame] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [isendgame, setShowEndgame] = useState(false);
  const [iswaiting, setShowWaitPage] = useState(false);
  const [average, setAverage] = useState(0);
  const [highestCard, setHighestCard] = useState("");
  const [highestCardDescription, setHighestCardDescription] = useState("");
  const [finalDecision, setFinalDecisionData] = useState("still ongoing");
  const [finalGame, setfinalGame] = useState(0);
  const [feedbacktype, setFeedBackType] = useState(0);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [playerName, setPlayerName] = useState("")
  const [highestAvgCard, setHighestAvgCard] = useState(null);
  const [showEndgameSurvey, setShowEndgameSurvey] = useState(false);
  const [showFinalDecision, setFinalDecision] = useState(false);

  const [redirectRoom, setNewGame] = useState(false);

  const onClick = () => {
    setTextHidden(!isTextHidden);
  };

  const handleIsGameScenarioModalClosed = () => {
    setIsGameScenarioModalClosed(true);
    setShowGameScenarioModal(false);
  };

  const [CONFIG_MAP, setConfigMap] = useState(null);
  let configMap = CONFIG_MAP ? CONFIG_MAP : DEFAULT_CONFIG;
  const [prevRound, setPrevRound] = useState(1);
  const [countdownDuration, setCountdownDuration] = useState(null);
  const [resetCountdown, setResetCountdown] = useState(false);

  /* Control Modals for Game Instructions and Description */
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const handleInstructionsClose = () => setShowInstructionsModal(false);
  const handleDescriptionClose = () => setShowDescriptionModal(false);
  const handleInstructionsShow = () => setShowInstructionsModal(true);
  const handleDescriptionShow = () => setShowDescriptionModal(true);

  /* Control Modal for Card */
  const [cards, setCards] = useState([]);
  const [showCardModal, setShowCardModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [activeCard, setActiveCard] = useState(null);
  const [playerCard, setPlayerCard] = useState(null);

  const handleOpenCardModal = (card) => {
    setSelectedCard(card);
    setShowCardModal(true);
    setActiveCard(card.name);

  };

  useEffect(() =>{
    setPlayerCard(null);
  }, [round]);


  const handlePlayCard = (card) => {
    handleSendMessage(
      card.name,
      card.description,
      card.keyword1,
      card.keyword2,
      card.keyword3
    );
    setDisabledButtons(prev => [...prev, card.name]);
    setPlayerCard({ name: card.name, round: round });
    setShowCardModal(false);
    setActiveCard(null);
    // goToSurveyPage();
  };

  const handleCloseCardModal = () => {
    setShowCardModal(false);
    setActiveCard(null);
  };

  /* Logic to figure out Opponents and their Cards */
  const [opponents, setOpponents] = useState([]);
  const [cardsByPlayer, setCardsByPlayer] = useState({});
  const [backgroundStyles, setBackgroundStyles] = useState([]);

  const cardColors = ["#ff6a85", "#f27457", "#f2ce1b", "#41bfb3"]; // Theme colors
  const cardImages = ["rabbit.png", "monkey.png", "owl.png", "porcupine.png"]; // Background images

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

 

  useEffect(() => {
    console.log("Room members:", members);
  }, [members]);


  const getUniquePlayerIndex = (players) => {
    const playerMap = {};
    // Sort players by their number to ensure consistent assignment
    const sortedPlayers = [...players].sort((a, b) => a.number - b.number);
    
    // Assign fixed indices based on player number
    sortedPlayers.forEach(player => {
      playerMap[player.number] = (player.number - 1) % 4;  // Maps 1->0, 2->1, 3->2, 4->3
    });
    
    return playerMap;
  };
  
  
  
  useEffect(() => {
    const playerIndices = getUniquePlayerIndex(members);
    
    // First, create a player map with the original ordering
    const playerInfo = members.map(member => ({
      playerNumber: `Player ${member.number}`,
      name: member.fullname || `Player ${member.number}`,
      isComputer: !member.fullname,
      originalNumber: member.number
    }));
  
    // Sort based on original player numbers
    playerInfo.sort((a, b) => a.originalNumber - b.originalNumber);
  
    // Now assign colors and avatars based on the sorted order
    const playerStylesMap = playerInfo.map((player, index) => ({
      ...player,
      avatarImage: cardImages[index],
      color: cardColors[index]
    }));
  
    // Move current player to correct position without changing their style
    const finalPlayerInfo = playerStylesMap.sort((a, b) => {
      if (`Player ${a.originalNumber}` === player_number) return 1;
      if (`Player ${b.originalNumber}` === player_number) return -1;
      return 0;
    });
  
    setOpponents(finalPlayerInfo);
    
    const backgroundStyles = finalPlayerInfo.map(player => ({
      backgroundColor: player.color,
      backgroundImage: player.avatarImage
    }));
  
    setBackgroundStyles(backgroundStyles);
  }, [members, player_number]);

  useEffect(() => {
    if (members && members.length > 0) {
      // Find the current player's data
      const currentPlayer = members.find(member => `Player ${member.number}` === player_number);
      if (currentPlayer) {
        setPlayerName(currentPlayer.fullname);
      }
    }
  }, [members, player_number]);

  // Group cards by player number and reverse each player's cards array
  useEffect(() => {
    const newCardsByPlayer = messages.reduce((acc, message) => {
      const playerLabel = `Player ${message.playerNo.split(" ")[1]}`;
      const cardData = {
        cardName: message.card.replace(" was shared", ""),
        description: message.description,
        cardKeyword1: message.cardKeyword1,
        cardKeyword2: message.cardKeyword2,
        cardKeyword3: message.cardKeyword3,
        latest: false, // Add latest flag with default value false
      };
      const existingPlayer = acc.find(
        (player) => player.player === playerLabel
      );
      if (existingPlayer) {
        existingPlayer.cards.push(cardData);
      } else {
        acc.push({ player: playerLabel, cards: [cardData] });
      }
      return acc;
    }, []);

    // Find the maximum length of cards array among all players
    const maxCardsLength = Math.max(
      ...newCardsByPlayer.map((player) => player.cards.length)
    );

    // Reverse the cards and set the latest flag
    const updatedCardsByPlayer = newCardsByPlayer.map((player) => {
      const reversedCards = player.cards.reverse();
      if (reversedCards.length === maxCardsLength) {
        if (reversedCards.length > 0) {
          reversedCards[0].latest = true; // Set latest flag for the topmost card
        }
      } else {
        // Ensure latest flag is false for all cards if the player has not played in the current round
        reversedCards.forEach((card) => (card.latest = false));
      }
      return { ...player, cards: reversedCards };
    });

    setCardsByPlayer(updatedCardsByPlayer);
  }, [messages]);

  console.log(cardsByPlayer);

  // Helper function to get the latest set of cards
  // const getLatestSet = (cardsByPlayer, numPlayers) => {
  //   const latestSet = [];
  //   let count = 0;
  //   for (const player of cardsByPlayer) {
  //     if (player.cards.length > 0) {
  //       latestSet.push(player.cards[0]);
  //       count++;
  //     }
  //     if (count >= numPlayers) break;
  //   }
  //   return latestSet;
  // };

  // const latestSet = getLatestSet(cardsByPlayer, members.length);

  useEffect(() => {
    if (player_number !== "Unknown") {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (CONFIG_MAP == null) {
        fetch(
          `${process.env.REACT_APP_API_URL}/getconfig?roomId=${roomId}&playerNum=${playerNumber}`,
          options
        )
          .then((response) => response.json())
          .then((data) => {
            return data;
          })
          .then((data) => {
            setConfigMap(data.config);
            setFeedBackType(configMap.FEEDBACK_TYPE);
            setCards(data.cards);
            setFeedbackQuestions(data.feedback_questions);
            setGameEndQuestions(data.game_end_questions);
            setGameDescription(data.game_description);
            setMidGameQuestions(data.midgame_questions);
          });
      }
    }
  }, [roomId, CONFIG_MAP, playerNumber, player_number, gameNum]);

  useEffect(() => {
    if (!countofsurvey) {
      setCountdownDuration(Date.now() + COUNTDOWN_DURATION); // 21M
      setShowWaitPage(false);
      const fetchHighestAverage = async () => {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
        //console.log("The members in the room "+members)
        fetch(
          `${
            process.env.REACT_APP_API_URL
          }/gethighestavg?roomId=${roomId}&players=${JSON.stringify(
            members
          )}&cards=${messages}&round=${round}`,
          options
        ) //22/5
          .then((response) => response.json())
          .then((data) => {
            setHighestCard(data.card);
            setHighestCardDescription(data.card_description);
          });
      };
      if (members.length > 0) {
        fetchHighestAverage();
      }
      if (showFinalDecision) {
        setCountdownDuration(Date.now() + COUNTDOWN_DURATION); // 21M
        setShowWaitPage(false);
        const getFinalDecision = async () => {
          console.log(
            "Room.jsx: Now the final decision page should be fetched"
          );
          // console.log("Inside fetchAverage: "+card)
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          };
          //console.log("The members in the room "+members)
          fetch(
            `${
              process.env.REACT_APP_API_URL
            }/getfinal?roomId=${roomId}&players=${JSON.stringify(
              members
            )}&gameNum=${gameNum}`,
            options
          )
            .then((response) => response.json())
            .then((data) => {
              console.log("Room.jsx: The final decision is " + data.final);
              setFinalDecisionData(data.final);
            });
        };
        if (members.length > 0) {
          getFinalDecision();
        }
      }
    }
  }, [countofsurvey]);

  useEffect(() => {
    if (isendgame) {
      callEndGameSurveyPage();
    }
  }, [isendgame]);

  useEffect(() => {
    if (gameEnd) {
      console.log("endGame");
      setEndGame(true);
    }
  }, [gameEnd]);

  useEffect(() => {
    if (roomEnd) {
      console.log("Room End endGame");
      setEndGame(true);
      setfinalGame(true);
    }
  }, [roomEnd]);

  useEffect(() => {
    if (redirectRoom) {
      startNewGame();
    }
  }, [redirectRoom]);

  useEffect(() => {
    const fetchAverage = async (card) => {
      // console.log("Inside fetchAverage: "+card)
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.log("The members in the room "+members)
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/getaverage?roomId=${roomId}&card=${card}&players=${JSON.stringify(
          members
        )}`,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log("Average data of the card");
          // console.log(data);
          setAverage((prevState) => ({
            ...prevState,
            [card]: data.avg, // update the average object with the new value
          }));
          //return data;
        });
    };
    messages.forEach((message) => {
      const card = message.body.substring(5, 7).trim();
      // console.log("Room.jsx The card is: "+message.body.substring(5, 7).trim())
      fetchAverage(card); // fetch average value for each card
    });
  }, [messages]);

  let countdownApi = null;
  const setCountdownRef = (countdown) => {
    if (countdown) {
      countdownApi = countdown.getApi();
    }
  };

  const handleCountdownEnd = () => {
    console.log("Sending default message");
    handleSendMessage(
      cards[0].name,
      cards[0].description,
      cards[0].keyword1,
      cards[0].keyword2,
      cards[0].keyword3
    );
  };

  const setPrevroundAndResetTimer = (round) => {
    setCountdownDuration(Date.now() + COUNTDOWN_DURATION);
    setPrevRound(round);
  };

  const handleSendMessage = (
    cardName,
    cardDescription,
    cardKeyword1,
    cardKeyword2,
    cardKeyword3
  ) => {
    //20JUL
    if (countdownApi) {
      countdownApi.pause();
    }
    const timeSpent = parseInt(
      (COUNTDOWN_DURATION - countdownDuration + Date.now()) / 1000
    );
    let request = {
      roomid: roomId,
      game_num: gameNum,
      round_num: round,
      player_num: "Player " + playerNumber,
      card_selected: cardName,
      time_spent: timeSpent,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    };

    fetch(`${process.env.REACT_APP_API_URL}/moves`, options)
      .then((response) => response.json())
      .then((data) => console.log(data));

    sendMessage(
      cardName,
      round,
      cardDescription,
      cardKeyword1,
      cardKeyword2,
      cardKeyword3
    ); //20JUL
  };

  const goToSurveyPage = () => {
    const message = messages[messages.length - 1].body.substring(0, 7); // 27M
    return (
      <div className="popup-box">
        <Survey
          setEndGame={setEndGame}
          setMidGame={setMidGame}
          sendSurveySubmit={sendSurveySubmit}
          setShowWaitPage={setShowWaitPage}
          countofsurvey={countofsurvey}
          setShowMidgame={setShowMidgame}
          setShowEndgame={setShowEndgame}
          setPrevRound={setPrevRound}
          round={round}
          cardMessage={messages}
          roomId={roomId}
          playerNumber={playerNumber}
          gameNum={gameNum}
          feedbackQuestions={feedbackQuestions}
          displayDelay={configMap.DISPLAY_SURVEY_DELAY}
          midgame={midGame}
          endgame={endGame}
          players={configMap.PLAYER_LIMIT_PER_ROOM}
        />
      </div>
    );
  };

  const goToMidAGameSurveyPage = () => {
    console.log("Now the midgame page should be loaded");
    return (
      <div className="popup-box">
        <MidGameSurvey
          setShowMidgame={setShowMidgame}
          setPrevroundAndResetTimer={setPrevroundAndResetTimer}
          round={round}
          roomId={roomId}
          playerNumber={playerNumber}
          gameNum={gameNum}
          midGameQuestions={midGameQuestions}
          displayDelay={configMap.DISPLAY_SURVEY_DELAY}
        />
      </div>
    );
  };

  const callEndGameSurveyPage = () => {
    console.log("Inside callEndGameSurveyPage");
    const calculateHigestAvgAndgoToEndGamePage = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.log("The members in the room "+members)
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/gethighestavg?roomId=${roomId}&players=${JSON.stringify(
          members
        )}&cards=${JSON.parse(
          localStorage.getItem("messages_list")
        )}&round=${round}`,
        options
      ) //13/5
        .then((response) => response.json())
        .then((data) => {
          console.log(
            "The higest rated card inside the callEndGameSurveyPage is ********" +
              data.card
          );
          setHighestAvgCard(data.card);
          setShowEndgameSurvey(true);
          console.log(
            "The showendgame state is set to true ",
            showEndgameSurvey
          );
        });
    };
    console.log("Now the endgamesurvey variable is set to false");
    calculateHigestAvgAndgoToEndGamePage();
  };

  const goToendGameSurveyPage = () => {
    console.log("goToendGameSurveyPage is called");
    console.log("Now the endgame survey page should be loaded");
    return (
      <EndgameSurvey
        setFinalDecision={setFinalDecision}
        setShowEndgameSurvey={setShowEndgameSurvey}
        setShowEndgame={setShowEndgame}
        roomId={roomId}
        sendSurveySubmit={sendSurveySubmit}
        setShowWaitPage={setShowWaitPage}
        countofsurvey={countofsurvey}
        members={members}
        currentPlayer={playerNumber}
        gameNum={gameNum}
        highestavg_card={highestAvgCard}
        gameEndQuestions={gameEndQuestions}
        messages={messages}
        displayDelay={configMap.DISPLAY_SURVEY_DELAY}
      />
    );
  };

  const getFinalDecisionPage = () => {
    console.log("goToendGameSurveyPage is called");
    console.log("Now the endgame survey page should be loaded");
    return (
      <FinalDecision
        setNewGame={setNewGame}
        sendSocketDisconnect={sendSocketDisconnect}
        setFinalDecision={setFinalDecision}
        roomId={roomId}
        sendSurveySubmit={sendSurveySubmit}
        setShowWaitPage={setShowWaitPage}
        countofsurvey={countofsurvey}
        members={members}
        currentPlayer={playerNumber}
        gameNum={gameNum}
        finalGame={finalGame}
        final={finalDecision}
      />
    );
  };

  const startNewGame = () => {
    console.log("Starting a new game", player_number);
    console.log("Starting a new game 1", playerNumber);
    history.push(`/newgame/${roomId}/${playerNumber}/?game=${gameNum}`);
    //setTimeout(() => window.location.reload(), 100);
    //Window refresh
  };

  // if (roomEnd) {
  //    setfinalGame(true)
  // }

  // if (finalGame) {
  //       history.push(`/reflections/${roomId}/${playerNumber}`, {"members": members, "currentPlayer": playerNumber});
  //       }

  let renderContent = <div></div>;
  if (members.length < configMap.PLAYER_LIMIT_PER_ROOM) {
    console.log("The current members in the room are: "+ JSON.stringify(members))
    renderContent = (
      <div className="member-lobby">
        <h2>Waiting for more players...</h2>
        <Col>
          <Row>
            <h3 className="title">Members:</h3>
          </Row>
          <Row>
            <BootstrapCard style={{ width: "30rem", zIndex: -1 }}>
              <ul style={{ padding: "0.5rem", color: "black" }}>
                {members.map((member, i) => (
                  <li key={i}>{member.fullname + ": " + `Player ID ${member.number}`}</li>
                ))}
              </ul>
            </BootstrapCard>
          </Row>
        </Col>
      </div>
    );
  } else if (countofsurvey && iswaiting) {
    renderContent = (
      <div className="member-lobby">
        <h2>Waiting for more players...</h2>
        <Col>
          <Row>
            <h3 className="title">
              Please wait till the other players finish.
            </h3>
          </Row>
        </Col>
      </div>
    );
  } else {
    if (resetCountdown === false && isGameScenarioModalClosed) {
      setCountdownDuration(Date.now() + COUNTDOWN_DURATION);
      setResetCountdown(true);
    }

    renderContent = (
      <div className="header-div">
        {showGameScenarioModal ? (
          <Game
            description={gameDescription.split("<br>").map((line, index) => (
              <div key={index} style={{ marginLeft: 20, fontSize: "16px" }}>
                {line}
              </div>
            ))}
            handleisClosed={handleIsGameScenarioModalClosed}
          />
        ) : null}
        <div className="body">
          <div className="informationContainer">
            <div className="modalContainer">
              <div className="gameInstructions">
                <button
                  onClick={handleInstructionsShow}
                  className="modalButton"
                >
                  Game Instructions
                </button>
                <ModalComponent
                  show={showInstructionsModal}
                  handleClose={handleInstructionsClose}
                  title="Game Instructions"
                >
                  <ul className="gameInstructionsList">
                    <li>
                      Every group member will have different cards, that some
                      are more useful than others.
                    </li>
                    <li>
                      You will exchange cards for 6 rounds. You will need to
                      choose a card in 3 minutes.
                    </li>
                    <li>
                      Please do your best to select the best possible card to
                      share with your group each time.
                    </li>
                    <li>
                      You will have time to read and evaluate the cards shared
                      after each round.
                    </li>
                    <li>
                      Please check the top-rated card to learn the group
                      opinions.
                    </li>
                    <li>
                      You will need to fill out a mid-game survey after 3 rounds
                      and make the final decision at the end of card exchange.
                    </li>
                    <li>
                      The goal of the game is to reach the best group
                      performance.
                    </li>
                  </ul>
                </ModalComponent>
              </div>
              <div className="gameDescription">
                <button onClick={handleDescriptionShow} className="modalButton">
                  Game Description
                </button>
                <ModalComponent
                  show={showDescriptionModal}
                  handleClose={handleDescriptionClose}
                  title="Game Description"
                >
                  <div style={{ fontSize: "15px" }}>
                    {gameDescription?.split("<br>").map((line, index) => (
                      <div key={index} style={{ marginLeft: 20 }}>
                        {line}
                      </div>
                    ))}
                  </div>
                </ModalComponent>
              </div>
            </div>
            {highestCard && highestCardDescription && (
              <div className="highestRatedCardContainer">
                <p className="highestRatedCardTitle">The Higest Rated Card</p>
                {/* <p className="highestRatedCardName">{highestCard}</p> */}
                <p className="highestRatedCardDescription">
                  {highestCardDescription}
                </p>
              </div>
            )}
            <div className="roomInformationContainer">
              <div className="roomInformation">
                <p className="roomInformationTitle">Room:</p>
                <p className="roomInformationText">{roomId}</p>
              </div>
              <div className="roomInformation">
                <p className="roomInformationTitle">Round:</p>
                <p className="roomInformationText">{round}</p>
              </div>
              <div className="roomInformation">
                <p className="roomInformationTitle">Player Name:</p>
                <p className="roomInformationText">{playerName}</p>
              </div>
              <div className="roomInformation">
                <p className="roomInformationTitle">Player Number:</p>
                <p className="roomInformationText">{player_number}</p>
              </div>
              <div className="roomInformation">
                <p className="roomInformationTitle">Game:</p>
                <p className="roomInformationText">{gameNum}</p>
              </div>
            </div>
            {countdownDuration ? (
              <div className="countdownContainer">
                <Countdown
                  ref={setCountdownRef}
                  date={countdownDuration}
                  key={countdownDuration}
                  renderer={countdownRenderer}
                  onComplete={handleCountdownEnd}
                />
              </div>
            ) : null}
            <React.Fragment>
              {prevRound !== round && goToSurveyPage()}
              {endGame && goToSurveyPage()}
              {ismidgame && goToMidAGameSurveyPage()}
              {showEndgameSurvey && goToendGameSurveyPage()}
              {showFinalDecision && getFinalDecisionPage()}
            </React.Fragment>

            {/* <div>
            <Row>
              <h3 className="title">Members:</h3>
            </Row>
            <Row>
              <BootstrapCard style={{ width: "300px", zIndex: -1 }}>
                <ul>
                  {members.map((member, i) => (
                    <li>{"Player" + member["number"]}</li>
                  ))}
                </ul>
              </BootstrapCard>
            </Row>
            <br />
          </div> */}
          </div>
      

          <div className="cardsContainer">
            <div className="allCardsContainer">
              {opponents.map((player, index) => {
                const isCurrentPlayer = player.playerNumber === player_number;

                return (
                  <div key={index} className="opponentColumn">
                  <div className="playerInfoContainer">
                    <img
                      src={`/sprites/${backgroundStyles[index]?.backgroundImage}`}
                      className="opponentAvatar"
                      alt={`Avatar for ${player.name}`}
                    />
                    <div className="playerInfo">
                      <p className="opponentHeader">
                        {isCurrentPlayer ? "You" : player.playerNumber}
                      </p>
                      <p className="playerName">{player.name}</p>
                    </div>
                  </div>
                  <div className="opponentCardContainer">
                    {cardsByPlayer
                      .filter((p) => p.player === player.playerNumber)
                      .flatMap((p) => p.cards)
                      .map((card, idx) => (
                          <div
                            key={idx}
                            className="opponentCard"
                            style={{
                              border: `0.5rem solid ${backgroundStyles[index]?.backgroundColor}`,
                            }}
                          >
                            {card?.latest === true && (
                              <div className="newBadge">NEW</div>
                            )}
                            <p className="opponentCardContent">
                              {card?.cardKeyword1}
                            </p>
                            <p className="opponentCardContent">
                              {card?.cardKeyword2}
                            </p>
                            <p className="opponentCardContent">
                              {card?.cardKeyword3}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
 
            

              {/* <div className="messages-container">
                <h2>All cards shared so far</h2>
                <ol className="messages-list">
                  {messages &&
                    messages.map((message, i) => (
                      //<Card key={i} owner={message.ownedByCurrentUser} body={message.body} average={average[message.body.substring(5, 7).trim()]} /> //18A
                      <Card
                        key={i}
                        owner={message.ownedByCurrentUser}
                        body={message.body}
                        average={
                          configMap.FEEDBACK_TYPE == 2
                            ? average[message.body.substring(5, 7).trim()]
                            : undefined
                        }
                        description={
                          <div style={{ fontSize: "12px", width: "200px" }}>
                            {message.description}
                          </div>
                        }
                      />
                    ))}
                </ol>
              </div> */}
            </div>
            <div className="userCardsContainer">
              {/* <h2>Recently shared cards</h2>
              <div className="curr-round-card-holder">
                <ul className="hor-messages-list">
                  {messages
                    .slice(
                      Math.max(
                        messages.length - configMap.PLAYER_LIMIT_PER_ROOM,
                        0
                      )
                    )
                    .map(
                      (
                        message,
                        i //20JUL
                      ) => (
                        <div>
                          <Card
                            key={i}
                            owner={message.ownedByCurrentUser}
                            body={message.body}
                            average={
                              configMap.FEEDBACK_TYPE == 2
                                ? average[message.body.substring(5, 7).trim()]
                                : undefined
                            }
                            description={
                              <div style={{ fontSize: "12px", width: "200px" }}>
                                {message.description}
                              </div>
                            }
                          />
                        </div>
                      )
                    )}
                </ul>
              </div> */}
              {cards?.map((card) => (
                <button
                  key={card.name}
                  onClick={() => handleOpenCardModal(card)}
                  className="userCard"
                  style={{
                    backgroundColor: disabledButtons.includes(card.name)
                      ? "grey"
                      : activeCard === card.name
                      ? "var(--theme-pink)"
                      : "var(--theme-white)",
                    color: disabledButtons.includes(card.name)
                      ? "white"
                      : "black",
                    border: disabledButtons.includes(card.name)
                      ? "none"
                      : `0.5rem solid #ff6a85`,
                    transform:
                      activeCard === card.name ? "scale(1.05)" : "scale(1)",
                    
                  }}
                >
                  <div className="defaultCardContent">
                    <p className="defaultCardContentText">{card?.keyword1}</p>
                    <p className="defaultCardContentText">{card?.keyword2}</p>
                    <p className="defaultCardContentText">{card?.keyword3}</p>
                  </div>
                </button>
              ))}

              {selectedCard && (
                <CardModal
                  show={showCardModal}
                  handleClose={handleCloseCardModal}
                  handlePlay={() => handlePlayCard(selectedCard)}
                  title={selectedCard.name}
                  isCardPlayed={disabledButtons.includes(selectedCard?.name)}
                  isPlayedRound={playerCard !== null && playerCard.round === round}
                >
                  <p>{selectedCard.description}</p>
                </CardModal>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return renderContent;
};

export default Room;