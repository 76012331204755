import React from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "./CardModal.css";

const CardModal = ({ show, handleClose, title, children, handlePlay, isCardPlayed, isPlayedRound }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Body className="modal-body body">
        {children}
        <div className="button-container">
          <Button
            variant="secondary"
            className="custom-button"
            onClick={handleClose}
          >
            Back
          </Button>
          {!isCardPlayed && !isPlayedRound && (<Button
            variant="primary"
            className="custom-button"
            style={{ backgroundColor: "#327957" }}
            onClick={() => {
              handlePlay();  // This will trigger the survey to show
            }}
          >
            Play
          </Button>)}
          <br></br>
          {(isCardPlayed || isPlayedRound) && (<p>You have already selected a card. This card is only for you to read only. View more cards for future rounds selection</p>)}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CardModal;
